define("discourse/plugins/discourse-user-card-badges/discourse/templates/connectors/user-card-additional-controls/user-card-badges", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.user.card_badge}}
    <LinkTo
      @route="badges.show"
      @model={{this.user.card_badge}}
      class="card-badge"
    >
      <img src={{this.user.card_badge.image}} alt={{this.user.card_badge.name}} />
    </LinkTo>
  {{/if}}
  */
  {
    "id": "qBaUB/Qm",
    "block": "[[[41,[30,0,[\"user\",\"card_badge\"]],[[[1,\"  \"],[8,[39,1],[[24,0,\"card-badge\"]],[[\"@route\",\"@model\"],[\"badges.show\",[30,0,[\"user\",\"card_badge\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[10,\"img\"],[15,\"src\",[30,0,[\"user\",\"card_badge\",\"image\"]]],[15,\"alt\",[30,0,[\"user\",\"card_badge\",\"name\"]]],[12],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"link-to\"]]",
    "moduleName": "discourse/plugins/discourse-user-card-badges/discourse/templates/connectors/user-card-additional-controls/user-card-badges.hbs",
    "isStrictMode": false
  });
});