define("discourse/plugins/discourse-user-card-badges/discourse/templates/connectors/user-preferences-profile/user-card-badges", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.siteSettings.user_card_badges_enabled}}
    <div class="control-group pref-card-badge">
      <label class="control-label">{{i18n "user.card_badge.title"}}</label>
      <div class="controls">
        <div class="card-image-preview">
          {{#if this.model.card_image_badge}}
            <img
              src={{this.model.card_image_badge.url}}
              alt={{this.user.card_badge.name}}
            />
          {{else}}
            {{i18n "user.card_badge.none"}}
          {{/if}}
        </div>
        <LinkTo
          @route="preferences.card-badge"
          class="btn btn-small pad-left no-text"
        >{{d-icon "pencil-alt"}}</LinkTo>
      </div>
    </div>
  {{/if}}
  */
  {
    "id": "FYuqi+yk",
    "block": "[[[41,[30,0,[\"siteSettings\",\"user_card_badges_enabled\"]],[[[1,\"  \"],[10,0],[14,0,\"control-group pref-card-badge\"],[12],[1,\"\\n    \"],[10,\"label\"],[14,0,\"control-label\"],[12],[1,[28,[35,1],[\"user.card_badge.title\"],null]],[13],[1,\"\\n    \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"card-image-preview\"],[12],[1,\"\\n\"],[41,[30,0,[\"model\",\"card_image_badge\"]],[[[1,\"          \"],[10,\"img\"],[15,\"src\",[30,0,[\"model\",\"card_image_badge\",\"url\"]]],[15,\"alt\",[30,0,[\"user\",\"card_badge\",\"name\"]]],[12],[13],[1,\"\\n\"]],[]],[[[1,\"          \"],[1,[28,[35,1],[\"user.card_badge.none\"],null]],[1,\"\\n\"]],[]]],[1,\"      \"],[13],[1,\"\\n      \"],[8,[39,2],[[24,0,\"btn btn-small pad-left no-text\"]],[[\"@route\"],[\"preferences.card-badge\"]],[[\"default\"],[[[[1,[28,[35,3],[\"pencil-alt\"],null]]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"i18n\",\"link-to\",\"d-icon\"]]",
    "moduleName": "discourse/plugins/discourse-user-card-badges/discourse/templates/connectors/user-preferences-profile/user-card-badges.hbs",
    "isStrictMode": false
  });
});