define("discourse/plugins/discourse-user-card-badges/discourse/templates/preferences/card-badge", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <section class="user-content">
    <form class="form-horizontal">
      <div class="control-group">
        <div class="controls">
          <h3>{{i18n "user.card_badge.title"}}</h3>
        </div>
      </div>
  
      <div class="control-group">
        <label class="control-label"></label>
        <div class="controls">
          <ComboBox
            class="user-card-badge-select"
            @value={{this.selectedUserBadgeId}}
            @nameProperty="badge.name"
            @content={{this.selectableUserBadges}}
            @onChange={{action (mut this.selectedUserBadgeId)}}
          />
        </div>
      </div>
  
      <div class="control-group">
        <div class="controls">
          <DButton
            class="btn-primary"
            @disabled={{this.saving}}
            @label={{this.savingStatus}}
            @action={{action "save"}}
          />
  
          {{#if this.saved}}{{i18n "saved"}}{{/if}}
        </div>
      </div>
    </form>
  </section>
  */
  {
    "id": "VgGN1XCf",
    "block": "[[[10,\"section\"],[14,0,\"user-content\"],[12],[1,\"\\n  \"],[10,\"form\"],[14,0,\"form-horizontal\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"control-group\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n        \"],[10,\"h3\"],[12],[1,[28,[35,0],[\"user.card_badge.title\"],null]],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"control-group\"],[12],[1,\"\\n      \"],[10,\"label\"],[14,0,\"control-label\"],[12],[13],[1,\"\\n      \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n        \"],[8,[39,1],[[24,0,\"user-card-badge-select\"]],[[\"@value\",\"@nameProperty\",\"@content\",\"@onChange\"],[[30,0,[\"selectedUserBadgeId\"]],\"badge.name\",[30,0,[\"selectableUserBadges\"]],[28,[37,2],[[30,0],[28,[37,3],[[30,0,[\"selectedUserBadgeId\"]]],null]],null]]],null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"control-group\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n        \"],[8,[39,4],[[24,0,\"btn-primary\"]],[[\"@disabled\",\"@label\",\"@action\"],[[30,0,[\"saving\"]],[30,0,[\"savingStatus\"]],[28,[37,2],[[30,0],\"save\"],null]]],null],[1,\"\\n\\n        \"],[41,[30,0,[\"saved\"]],[[[1,[28,[35,0],[\"saved\"],null]]],[]],null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"i18n\",\"combo-box\",\"action\",\"mut\",\"d-button\",\"if\"]]",
    "moduleName": "discourse/plugins/discourse-user-card-badges/discourse/templates/preferences/card-badge.hbs",
    "isStrictMode": false
  });
});